import { NgModule } from '@angular/core';
import {CmsComponent} from "./cms.component";
import {SharedModule} from "../shared/shared.module";
import {CmsRoutingModule} from "./cms-routing.module";
import {SafeHtmlPipe} from "../pipes/SafeHtmlPipe";

@NgModule({
    declarations: [
        CmsComponent,
        SafeHtmlPipe
    ],
    exports: [
        SafeHtmlPipe
    ],
    imports: [
        SharedModule,
        CmsRoutingModule
    ]
})
export class CmsModule { }
