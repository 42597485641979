import {NgModule} from "@angular/core";
import {CreateIdeabookComponent} from "./create-ideabook.component";
import {SharedModule} from "../shared/shared.module";

@NgModule({
    declarations: [CreateIdeabookComponent],
    imports: [
        SharedModule
    ],
    entryComponents: [
        CreateIdeabookComponent
    ],
    exports: [
        CreateIdeabookComponent
    ]
})

export class CreateIdeabookModule {
    
}
