import { NgModule } from '@angular/core';
import {UserAccountMenuComponent} from "./user-account-menu.component";
import {SharedModule} from "../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    UserAccountMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    UserAccountMenuComponent
  ]
})
export class UserAccountMenuModule { }
