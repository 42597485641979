import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrls: ['./order-progress.component.css']
})
export class OrderProgressComponent implements OnInit {
  @Input() status: Number;
  constructor() { }

  ngOnInit() {
  }

}
