import {Component, EventEmitter, OnInit, Inject, Optional} from '@angular/core';
import {FormBuilder, FormGroup, NgForm} from "@angular/forms";
import {ConfigSettings} from "../config/config.settings";
import {debounceTime, finalize, first, switchMap, tap, map} from "rxjs/operators";
import {UserService} from "../config/user.service";
import {reject} from "q";
import {ConfigService} from "../config/config.service";
import {Ideabook} from "../models/ideabook";
import {Suggestion} from "../models/suggestion";
import {Invitees} from "../models/invitees";
import {Observable, Subject} from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {Router, NavigationEnd} from '@angular/router';

@Component({
  selector: 'app-create-ideabook',
  templateUrl: './create-ideabook.component.html',
  styleUrls: ['./create-ideabook.component.css']
})
export class CreateIdeabookComponent implements OnInit {
  user_id: number;
  lang: string;
  id: number = 0;
  name: string = '';
  description: string;
  ideabook: Ideabook;
  did_create: boolean = false;
  is_loading: boolean = false;
  isLoading = false;
  suggestions: any;
  input = new Subject<string | null>();
  invitees: string;
  searchForm: FormGroup;

  constructor(
      private configSettings: ConfigSettings,
      private userService: UserService,
      private configService: ConfigService,
      private formBuilder: FormBuilder,
      private dialogRef: MatDialogRef<CreateIdeabookComponent>,
      private router: Router,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.user_id = +response.id;
      }
    }, error => { this.configSettings.toggleLoading(false); });

    if(this.data){
	this.id = this.data.ideabook.id;
	this.name = this.data.ideabook.title;
	this.description = this.data.ideabook.description;
    }
  }

  onSubmit(ideabookForm: NgForm) { 
    this.doSave();
	if(this.name == ''){
    	    ideabookForm.reset();
	}
  }

  doSave() {
    this.is_loading = true;
    try {
      const getParams = {
        lang: this.lang
      };

      let action = '';
      let postParams: any = '';
      if(this.id == 0){
	action = 'create-idea-book';
	postParams = {
	    user_id: this.user_id,
	    title: this.name,
	    description: this.description
	};
      }else{
	action = 'update-idea-book';
	postParams = {
	    user_id: this.user_id,
	    title: this.name,
	    ideabook_id: this.id,
	    description: this.description
	};
      }
      this.configService.postRequest(action, getParams, postParams)
          .subscribe(response => {
            if(response.status === 200) {
              this.dialogRef.close(response.data);
              this.router.navigate(['/lookbook/'+response.data.id]);
            }
          }, error => { reject(error); });

    } catch (e) {

    }
  }

  closeDialog() { 
    this.dialogRef.close();
    return false;
  }

}
