import {NgModule} from "@angular/core";
import {OrderProgressComponent} from "./order-progress.component";
import {SharedModule} from "../shared/shared.module";

@NgModule({
    declarations: [
        OrderProgressComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        OrderProgressComponent
    ]
})

export class OrderProgressModule {}
