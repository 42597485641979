import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {CmsComponent} from "./cms.component";

const routes: Routes = [
  {path: 'about-us', component: CmsComponent, data: {id: 1}},
  {path: 'terms-and-conditions', component: CmsComponent, data: {id: 2}},
  {path: 'return-policy', component: CmsComponent, data: {id: 4}},
  {path: 'privacy-policy', component: CmsComponent, data: {id: 5}},
  {path: 'credits-and-vouchers', component: CmsComponent, data: {id: 7}},
  {path: 'gift-card', component: CmsComponent, data: {id: 8}},
  {path: 'payment-method', component: CmsComponent, data: {id: 9}},
  {path: 'shipping-and-delivery', component: CmsComponent, data: {id: 6}},
  {path: 'cookie-policy', component: CmsComponent, data: {id: 10}}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CmsRoutingModule {
}

